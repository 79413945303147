import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import AboutInfo from "../components/aboutinfo"

const StyledContainer = styled.div`
  width: 90%;
  margin: 2rem auto;  
`;

export default function AboutPage({ data }) {
  const abouts = data.abouts.nodes

  return (
    <>
      <SEO title="FAQ" />
      <StyledContainer>
        <AboutInfo abouts={abouts} />
      </StyledContainer>
    </>
  )
}

export const query = graphql`
  query aboutsQuery {
    abouts: allSanityAbouts {
    nodes {
      id
      name
      para1
      para2
      para3
      para4
      para5
    }
  }
}
`;

import React from "react"
import { Link } from "gatsby"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import styled from "styled-components"

const StyledContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: fit-content(90%);
  grid-template-rows: auto;
  background: transparent;
  justify-content: center;
  gap: 2rem;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 37.5rem);
    grid-template-rows: auto;
    justify-content: center;
  }
`

function AboutDetail({ about }) {
  return (
    <>
      <Card className="w-100 m-3">
        <Card.Header
          className="text-center p-3 m-3"
          style={{ textShadow: `0.2rem 0.2rem #412456` }}
        >
          <h3>{about.name}</h3>
        </Card.Header>
        <Card.Body className="m-2">
          <Card.Text
            className="pt-0 pl-3 pr-3"
            style={{ color: `var(--gold)` }}
          >
            {about.para1}
          </Card.Text>
          <Card.Text className="p-3" style={{ color: `var(--gold)` }}>
            {about.para2}
          </Card.Text>
          <Card.Text className="p-3" style={{ color: `var(--gold)` }}>
            {about.para3}
          </Card.Text>
          <Card.Text className="p-3" style={{ color: `var(--gold)` }}>
            {about.para4}
          </Card.Text>
        </Card.Body>
        <Card.Footer className="m-0 p-0">
          <Button className="w-100" type="button">
            <Link to="/readings">browse reading topics</Link>
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default function AboutInfo({ abouts }) {
  return (
    <StyledContainer>
      {abouts.map(about => (
        <AboutDetail key={about.id} about={about} />
      ))}
    </StyledContainer>
  )
}
